import { Component, OnInit,AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { ProductsListModel } from '../../shared/models/collection.model';
import { FeaturedProductsModel, CategoryList } from '../../shared/models/home.model';
import { ToastrService } from 'ngx-toastr';
import { HelperService } from '../../app.service';
declare var $: any;
declare const require: any;
var qs = require('qs');

@Component({
    selector: 'app-collection',
    templateUrl: './collection.component.html',
    styleUrls: ['./collection.component.scss']
})
export class CollectionComponent implements OnInit,AfterViewInit {
    public isLoading: boolean = false;
    public p = 1;
    public productList: Array<ProductsListModel> = [];
    public categoryList = [];
    public categoryId: Number = null;
    public brandList = [];
    public subCatFilter = [];
    public sizeList = [];
    public subIdCat: null;
    public selectedId = null;
    public filterBar = false;
    public filterBtn = true;
    public itemId = null;
    public quantiyObject = [];
    public submitingData: boolean = false;


    public collectionList = [{ title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 1", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }, { title: "Brand 2", subTitle: "Men Tees", Price: 567, image: "assets/img/pic4.png" }, { title: "Brand 3", subTitle: "Men Tees", Price: 567, image: "assets/img/pic5.png" }, { title: "Brand 4", subTitle: "Men Tees", Price: 567, image: "assets/img/pic3.png" }]
    constructor(private router: Router, private helpService: HelperService, private activeRoute: ActivatedRoute, private crud: CrudServiceService, private toastr: ToastrService) {
        this.activeRoute.params.subscribe((res: any) => {
            this.categoryId = res.id;
            if ((this.categoryId < 10000000000)) {
                // this.getProductById();
                // this.getSubCategoriesList(this.categoryId);
                // this.subCategoryFilterList();
            } else {
                this.searchList();
            }
            this.getSizeList();
        });
        this.checkUser();
        this.getAllCategories();
    }

    ngOnInit() {
        this.helpService.subCat.subscribe((res: any) => {
            if (res) {
                if (res.catId !== null && res.subCatId !== null) {
                    this.isLoading = true;
                    this.selectedId = res.subCatId;
                    this.crud.getData("product/subcategory/list?subcategorId=" + res.subCatId + "&categoryId=" + res.catId + "&channel=1").subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.productList = res.products;
                            this.isLoading = false;
                        }
                    }, error => {
                        this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
                        this.isLoading = false;
                    })
                    this.getSubCategoriesList(res.catId);
                    this.crud.getData('category/subcategory/list?categoryId=' + res.catId).subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.subCatFilter = res.subcategories;
                        } else {
                            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                        };

                    })
                }
                if (res.catId !== null && res.subCatId == null) {
                    this.isLoading = true;
                    this.crud.getData("product/list?categoryId=" + res.catId  + "&channel=1").subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.productList = res.products;
                            this.isLoading = false;
                        }
                    })
                    this.getSubCategoriesList(res.catId);
                    this.crud.getData('category/subcategory/list?categoryId=' + res.catId).subscribe((res: any) => {
                        if (res.responseCode == 200) {
                            this.subCatFilter = res.subcategories;
                        } else {
                            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                        };

                    })
                }
            }
        })
    }

    ngAfterViewInit() {
       setTimeout(function(){
            $(document).ready(function(){
                // Product List
                $('#list-view').click(function() {
                    $('#content .product-grid > .clearfix').remove();

                    $('#content .row > .product-grid').attr('class', 'product-layout product-list col-xs-12');
                    $('#grid-view').removeClass('active');
                    $('#list-view').addClass('active');

                    localStorage.setItem('display', 'list');
                });

                // Product Grid
                $('#grid-view').click(function() {
                    // What a shame bootstrap does not take into account dynamically loaded columns
                    var cols = $('#column-right, #column-left').length;

                    if (cols == 2) {
                        $('#content .product-list').attr('class', 'product-layout product-grid col-lg-6 col-md-6 col-sm-12 col-xs-12');
                    } else if (cols == 1) {
                        $('#content .product-list').attr('class', 'product-layout product-grid col-lg-3 col-md-4 col-sm-4 col-xs-12');
                    } else {
                        $('#content .product-list').attr('class', 'product-layout product-grid col-lg-3 col-md-3 col-sm-4 col-xs-12');
                    }

                    $('#list-view').removeClass('active');
                    $('#grid-view').addClass('active');

                    localStorage.setItem('display', 'grid');
                });

                if (localStorage.getItem('display') == 'list') {
                    $('#list-view').trigger('click');
                    $('#list-view').addClass('active');
                } else {
                    $('#grid-view').trigger('click');
                    $('#grid-view').addClass('active');
                }

                if($('.left-category').hasClass('treeview')==true){
                    $(".treeview-list").treeview({
                        animated: "slow",
                        collapsed: true,
                        unique: true
                    });
                   
                    $('.left-category.treeview-list a.active').parent().removeClass('expandable');
                    $('.left-category.treeview-list a.active').parent().addClass('collapsable');
                    $('.left-category.treeview-list .collapsable > ul.collapsable').css('display','block');
                }
            });   
       },3000);        
    }

    over(item) {
        this.itemId = item
    }

    public getCatId(id) {
        let body = {
          catId: id,
          subCatId: null
        }
        this.helpService.subCat.next(body);
        this.router.navigate(['/collectionByCategory/' + id])
    }

    public catId(catId, subCat) {
        let body = {
            catId: catId,
            subCatId: subCat.subCategoryId
        }
        this.router.navigate(['/collectionByCategory/' + this.itemId])
        this.helpService.subCat.next(body)
    }

    public searchList() {
        this.isLoading = true;
        this.crud.getData("product/search?productName=" + this.categoryId + "&channel=1").subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.categoryId = res.products[0].categoryId
                // console.log("response", res.products)
                this.productList = res.products;
                if (this.productList.length > 0) {
                    this.getSubCategoriesList(res.products[0].categoryId)
                }
                this.crud.getData('category/subcategory/list?categoryId=' + res.products[0].categoryId).subscribe((res: any) => {
                    if (res.responseCode == 200) {
                        this.subCatFilter = res.subcategories;
                    } else {
                        this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
                    };

                })
                // console.log("qwerty", this.productList)
                this.isLoading = false;
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    public checkUser() {
        if (localStorage.getItem("type") === "Regular" || localStorage.getItem("type") == undefined) {
            return false;
        } else {
            return true;
        }
    }

    public getProductById() {
        this.isLoading = true;
        if (this.categoryId !== null) {
            this.crud.getData('product/category/list?categoryId=' + this.categoryId).subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.productList = res.products;
                    this.isLoading = false;
                }
                // console.log('resdata', res)
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
            )
        }
    }

    public productDetails(id, catId) {
        // console.log(id, catId)
        this.router.navigate(['/catId/' + catId + '/product-detalil/' + id])
    }

    // sub category Filter
    public subCategoryFilterList() {
        this.crud.getData('category/subcategory/list?categoryId=' + this.categoryId).subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.subCatFilter = res.subcategories;
            } else {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 })
            };

        })
    }

    //get sub CatId
    public subCatIdSelect(id) {
        this.selectedId = id;
        this.crud.getData("product/subcategory/list?subcategorId=" + id + "&categoryId=" + this.categoryId + "&channel=1").subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.productList = res.products;

            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    //sub categories
    public getSubCategoriesList(id) {
        this.isLoading = true;
        if (id !== null) {
            this.crud.getData('category/brand/list?categoryId=' + id).subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.brandList = res.brands;
                    // console.log('resdataSub', res)
                }
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            }
            )
        }
    }

    //productByBrand
    public productByBrand(brandId) {
        this.crud.getData("product/brand/list?brandId=" + brandId + "&channel=1").subscribe((res) => {
            // console.log("resadata", res)
            if (res.responseCode == 200) {
                this.productList = res.products
            }
        })
    }

    public getSizeList() {
        this.crud.getData("size/list").subscribe((res) => {
            if (res.responseCode == 200) {
                this.sizeList = res.sizes
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    public selectSize(id) {
        this.crud.getData("product/size/list?sizeId=" + id + "&categoryId=" + this.categoryId + "&channel=1").subscribe((res) => {
            if (res.responseCode = 200) {
                this.productList = res.products;
            }
        }, error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    public openFilterMenu() {
        // console.log('this fn is called kakfksahfdk akshdfks ');
        this.filterBar = true;
        this.filterBtn = !this.filterBtn;
    }

    public closeFilterMenu() {

        this.filterBar = false;
        this.filterBtn = !this.filterBtn;
    }

    public getAllCategories(){
        this.crud.getData("category/list").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.categoryList = res.categories;                
                this.categoryList.forEach((element, i) => {
                    this.crud.getData("category/subcategory/list?categoryId=" + element.catId).subscribe((res) => {
                        if (res.responseCode == 200) {
                            this.categoryList[i].subCategories = res.subcategories;
                        }
                    })
                });

               
            }
        },error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        })
    }

    public selectQuant(productCode, qty){
        this.quantiyObject[productCode] =  qty;
    }

    public addToCart(code) {        
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            fetch(this.crud.getEndPoint("cart/add"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    email: localStorage.getItem("email"),
                    customerId: localStorage.getItem("customerid"),
                    productId: code,
                    quantity:  (this.quantiyObject[code] )  ? this.quantiyObject[code] : 1
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {                   
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                    this.router.navigate(['/cart-page'])
                }
                else{
                   this.toastr.error(response.responseMessage, response.description, { timeOut: 3000 });
                }
            }).catch(error => {
                console.error('Error:', error)
            });
        }
    }

    public addToWishList(prodId) {
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            if (localStorage.getItem("customerid") !== null || localStorage.getItem("customerid") !== undefined) {
                fetch(this.crud.getEndPoint("wishlist/create"),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                    },
                    body: qs.stringify({
                        customerId: localStorage.getItem("customerid"),
                        productId: prodId
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.responseCode == "200") {
                        this.helpService.wishListSubject.next(response.wishListItems)
                        this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                        this.router.navigate(['/wishlist']);
                        this.submitingData = false;
                    }
                }).catch(error => console.error('Error:', error));
                this.submitingData = false
            } else {
                this.toastr.warning("Please Login First", "Login In Required", { timeOut: 3000 })
                this.submitingData = false
            }
        }
    }
}
