import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CrudServiceService } from '../app/app-services/crud-service.service';
import { CrudInterceptorService } from '../app/app-services/crud-inspector.services';
import { OrderService } from './services/orderService.service';
import { Angular4PaystackModule } from 'angular4-paystack';

import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { RouterModule, Routes } from '@angular/router';
import { cartReducer } from './services/cart-store/cart.reducer';
import { StoreModule } from '@ngrx/store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { CollectionComponent } from './pages/collection/collection.component';
import { ProductDetailComponent } from './pages/product-detail/product-detail.component';
import { HelperService } from '../app/app.service';
import { CartPageComponent } from './pages/cart-page/cart-page.component';
import { FooterComponent } from './layout/footer/footer.component';
import { LoginComponent } from './pages/login/login.component';
import { ContentComponent } from './layout/content/content.component';
import { FullComponent } from './layout/full/full.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { PaymentPageComponent } from './pages/payment-page/payment-page.component';
import { AccountComponent } from './pages/account/account.component';
import { UserProfileComponent } from './pages/account/user-profile/user-profile.component';
import { SavedAddressComponent } from './pages/account/saved-address/saved-address.component';
import { WishlistComponent } from './pages/account/wishlist/wishlist.component';
import { AddressListComponent } from './pages/address-list/address-list.component'
import { LaddaModule } from 'angular2-ladda';
import { CookieModule } from "ngx-cookie";
import { NgxStripeModule } from 'ngx-stripe';
import { ThankyouPageComponent } from './pages/thankyou-page/thankyou-page.component';
import { ShippingCompanyComponent } from './pages/shipping-company/shipping-company.component';
import { PickupLocationComponent } from './pages/pickup-location/pickup-location.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/account/change-password/change-password.component';


export const FULL_ROUTES: Routes = [

    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "collectionByCategory/:id", component: CollectionComponent },
    { path: "catId/:id/product-detalil/:id", component: ProductDetailComponent },
    { path: "cart-page", component: CartPageComponent },
    { path: "my-orders", component: OrdersComponent },
    { path: "account", component: AccountComponent },
    { path: "address-list", component: AddressListComponent },
    { path: "payment", component: PaymentPageComponent },
    { path: "address", component: SavedAddressComponent },
    { path: "wishlist", component: WishlistComponent }
]

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    CollectionComponent,
    ProductDetailComponent,
    CartPageComponent,
    FooterComponent,
    LoginComponent,
    ContentComponent,
    FullComponent,
    OrdersComponent,
    PaymentPageComponent,
    AccountComponent,
    UserProfileComponent,
    SavedAddressComponent,
    WishlistComponent,
    AddressListComponent,
    ThankyouPageComponent,
    ShippingCompanyComponent,
    PickupLocationComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    OwlModule,
    HttpModule,
    LaddaModule,
    NgxPaginationModule,
    Angular4PaystackModule,
    ReactiveFormsModule,
    CookieModule.forRoot(),
    HttpClientModule,
    NgbModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    StoreModule.forRoot({ 'cart': cartReducer }),
    NgxStripeModule.forRoot('sk_test_f7uI7RuHX7uLjjuf6swE838R'),
    RouterModule.forRoot(FULL_ROUTES, { useHash: false })
  ],
  providers: [HelperService, CrudServiceService, OrderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CrudInterceptorService,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
