import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CrudServiceService } from '../../../app-services/crud-service.service';
declare const require: any;
var qs = require('qs');
var assert = require('assert');
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public isLoading: boolean = false;
  public isSaving: boolean = false;
  public userInfo = {
    customerid: null,
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null
  }
  constructor(private toastr: ToastrService, private crud: CrudServiceService) {
    this.isLoading = true;
    this.userInfo.customerid = localStorage.getItem("customerid");
    this.userInfo.email = localStorage.getItem("email");
    this.userInfo.firstName = localStorage.getItem("firstname");
    this.userInfo.lastName = localStorage.getItem("lastname");
    this.userInfo.phoneNumber = localStorage.getItem("phonenum");
    this.isLoading = false;
  }
  //edit profile
  editProfile() {
    this.isSaving = true;
     fetch(this.crud.getEndPoint("customer/updateProfile"),
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
        },
        body: qs.stringify({
          customerId: this.userInfo.customerid,
          fname: this.userInfo.firstName,
          lname: this.userInfo.lastName,
          email: this.userInfo.email,
          phoneNum: this.userInfo.phoneNumber
        })
      }).then(response => response.json())
      .then(response => {
        if (response.responseCode == "200") {
          this.isSaving = false;
          this.toastr.success(response.description, response.responseMessage, { timeOut: 3000 })
        } else if (response.responseCode == "100") {
          this.isSaving = false;
          this.toastr.warning(response.description, response.responseMessage, { timeOut: 3000 })
        } else {
          this.isSaving = false;
          this.toastr.error(response.description, response.responseMessage, { timeOut: 3000 })
        }
      })
  }

  ngOnInit() {
  }

}
