import { Component, OnInit,Directive, Input, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CrudServiceService } from '../../../app-services/crud-service.service';

declare const require: any;
var qs = require('qs');
var assert = require('assert');

@Component({
	selector: 'app-change-password',
	templateUrl: './change-password.component.html',
	styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {

	public isLoading: boolean = false;
	public isSaving: boolean = false;
	public userInfo = { userId: null,currentPass: null,	newPassword: null,confirmPassword:null};
	public isloggedIn: boolean = false;
	
	@ViewChild('changePasswordForm') changePasswordForm; 

	constructor(private toastr: ToastrService, private crud: CrudServiceService) { 
		this.isLoading = true;
	    this.userInfo.userId = localStorage.getItem("customerid");	   
	    this.isLoading = false;

	    if (localStorage.getItem("isloggedIn") == null) {
            
            this.isloggedIn = false;
        } else {
           
            this.isloggedIn = true;
        }
	}

	ngOnInit() {
	}

	changePasswrod() {
		this.isSaving = true;

		if(this.userInfo.newPassword != this.userInfo.confirmPassword){
			this.toastr.warning("Failed", "New Password do not match, Please try again!", { timeOut: 3000 });
			this.isSaving = false;
			return false;
		}

		fetch(this.crud.getEndPoint("customer/changePassword"),
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
			},
			body: qs.stringify({
				userId: this.userInfo.userId,
				currentPass: this.userInfo.currentPass,
				newPassword: this.userInfo.newPassword,				
			})
		}).then(response => response.json())
		.then(response => {
			if (response.responseCode == "200") {
				this.isSaving = false;
				this.toastr.success(response.description, response.responseMessage, { timeOut: 3000 });
				this.changePasswordForm.reset();
			} else if (response.responseCode == "100") {
				this.isSaving = false;
				this.toastr.warning(response.description, response.responseMessage, { timeOut: 3000 })
			} else {
				this.isSaving = false;
				this.toastr.error(response.description, response.responseMessage, { timeOut: 3000 })
			}
		})
	}
}
