import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { Router } from '@angular/router';
@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
    public isLoading: boolean = false;
    public wishlist = true;
    public savedAddress = false;
    public editProfile = false;
    public isloggedIn: boolean = false;

    constructor(public crud: CrudServiceService,private router: Router) {
        if (localStorage.getItem("isloggedIn") == null) {
            // this.cartTotal = localStorage.getItem("cartTotal")
            this.isloggedIn = false;
        } else {
            //this.getCartList();
            this.isloggedIn = true;
        }
    }
    //get wishlist data

    ngOnInit() {
    }

    showWishlist() {
        this.wishlist = true;
        this.savedAddress = false;
        this.editProfile = false;
    }
    showSavedAddress() {
        this.wishlist = false;
        this.savedAddress = true;
        this.editProfile = false;
    }
    showEditProfile() {
        this.wishlist = false;
        this.savedAddress = false;
        this.editProfile = true;
    }

    signout() {
        localStorage.clear();
        this.router.navigate(['/login'])
    }
}
