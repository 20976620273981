import { Component, OnInit,AfterViewInit } from '@angular/core';
import { HelperService } from '../../app.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrudServiceService } from '../../../app/app-services/crud-service.service'
import * as CartActions from '../../services/cart-store/cart.action';
import * as fromCart from '../../services/cart-store/cart.reducer';
import { Store } from '@ngrx/store';
import { ProductModel } from '../../shared/models/product.model';
import { timeout } from 'q';
declare const require: any;
var qs = require('qs');
var assert = require('assert');
declare var $: any;

@Component({
    selector: 'app-product-detail',
    templateUrl: './product-detail.component.html',
    styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit,AfterViewInit {
    public productId = null;
    public submitingData: boolean = false;
    public isLoading: boolean = false;
    public productDescription: Array<ProductModel> = [];
    public featuredProductList: Array<ProductModel> = [];
    public imgList = [];
    public selectimage = null;
    public cartNumber: number = 0;
    public cartData;
    public productQuant: number = 1;
    public currentImg(img) {
        // console.log(img)
        if (img !== null) {
            this.selectimage = img
        } else {
            this.selectimage = this.selectimage
        }
    }

    constructor(private store: Store<fromCart.CartState>, private helperService: HelperService, private crud: CrudServiceService, private router: Router, private toastr: ToastrService, private activeRoute: ActivatedRoute, ) {
        let catId = window.location.href.split("/")
        this.activeRoute.params.subscribe((res: any) => {
            this.productId = res.id;
        })
        this.getProductById(this.productId);
        this.getSimilarProduct(catId[catId.length - 3]);
        this.checkUser()
    }

    ngOnInit() {
        if($('.zoomContainer').length > 0){              
            $.removeData($(".product-additional-block"), 'elevateZoom');
            $("#zoom").removeData('elevateZoom');   
            $.removeData("#zoom",'elevateZoom'); 
            $('.zoomContainer').remove();                 
        } 

        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
    }

    ngOnDestroy(){
        if($('.zoomContainer').length > 0){
            $.removeData($(".product-additional-block"), 'elevateZoom');
            $("#zoom").removeData('elevateZoom');   
            $.removeData("#zoom",'elevateZoom'); 
            $('.zoomContainer').remove();    
        }  
    }   

    ngAfterViewInit(){      

        this.activeRoute.params.subscribe(routeParams => {

            if($('.zoomContainer').length > 0){              
                $.removeData($(".product-additional-block"), 'elevateZoom');
                $("#zoom").removeData('elevateZoom');   
                $.removeData("#zoom",'elevateZoom'); 
                $('.zoomContainer').remove();                 
            } 

            setTimeout(function(){

                $('select[name=\'recurring_id\'], input[name="quantity"]').change(function(){
                    $.ajax({
                        url: 'index.php?route=product/product/getRecurringDescription',
                        type: 'post',
                        data: $('input[name=\'product_id\'], input[name=\'quantity\'], select[name=\'recurring_id\']'),
                        dataType: 'json',
                        beforeSend: function() {
                            $('#recurring-description').html('');
                        },
                        success: function(json) {
                            $('.alert-dismissible, .text-danger').remove();

                            if (json['success']) {
                                $('#recurring-description').html(json['success']);
                            }
                        }
                    });
                });        
          
                $('#review').delegate('.pagination a', 'click', function(e) {
                    e.preventDefault();
                    $('#review').fadeOut('slow');
                    $('#review').load(this.href);
                    $('#review').fadeIn('slow');
                });

                $(document).ready(function() {
                    if ($(window).width() > 767) {
                        $("#zoom").elevateZoom({
                            gallery:'product-additional-block',                        
                            zoomType : "window",
                            cursor: "crosshair"
                        });
                        var z_index = 0;

                        $(document).on('click', '.thumbnail', function () {
                            $('.thumbnails').magnificPopup('open', z_index);
                            return false;
                        });

                        $('.product-additional-block a').on('mouseover', function() {
                            var smallImage = $(this).attr('data-image');
                            var largeImage = $(this).attr('data-zoom-image');
                            var ez =   $('#zoom').data('elevateZoom');
                            $('.thumbnail').attr('href', largeImage);
                            ez.swaptheimage(smallImage, largeImage);
                            z_index = $(this).index('.product-additional-block a');
                            return false;
                        });
                    }else{
                        $(document).on('click', '.thumbnail', function () {
                            $('.thumbnails').magnificPopup('open', 0);
                            return false;
                        });
                    }
                
                    $('.thumbnails').magnificPopup({
                        delegate: 'a.elevatezoom-gallery',
                        type: 'image',
                        tLoading: 'Loading image #%curr%...',
                        mainClass: 'mfp-with-zoom',
                        gallery: {
                            enabled: true,
                            navigateByImgClick: true,
                            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
                        },
                        image: {
                            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                            titleSrc: function(item) {
                                return item.el.attr('title');
                            }
                        }
                    });

                    $('.image-additional').owlCarousel({
                        items: 4,
                        navigation: true,
                        pagination: false,
                        itemsDesktop : [1199, 3],
                        itemsDesktopSmall : [991, 3],
                        itemsTablet : [767, 3],
                        itemsTabletSmall : [479, 2],
                        itemsMobile : [319, 1]
                    });

                    $('.product-carousel').owlCarousel({
                        items: 5,
                        autoPlay: false,
                        singleItem: false,
                        navigation: true,
                        pagination: false,
                        itemsDesktop : [1199,4],
                        itemsDesktopSmall : [991,3],
                        itemsTablet : [479,2],
                        itemsMobile : [319,1]
                    });               
                });  
                this.refeaturedp();
                $(window).resize(function(){this.refeaturedp();});
            },3000); 
        });       
    }

    refeaturedp(){
        var size_item = $(".featured .section-product div.product-items").size();
        if ($(window).width() > 650){
          var x=5;
        }
        else{
          var x=4;
        }
        $('.featured .nomore').hide();
        $('.featured .section-product div.product-items:lt('+x+')').fadeIn('slow');
        $('.loadmore').click(function () {
            if(x==size_item){
                     $('.featured .nomore').show();
                      $('.featured .loadmore').hide();
            }else{
                if ($(window).width() > 650){
                 x= (x+5 <= size_item) ? x+5 : size_item;
                }
                else
                {
                  x= (x+2 <= size_item) ? x+2 : size_item;
                }
                $('.featured .section-product div.product-items:lt('+x+')').fadeIn('slow');
            }
        });
    }

    public getProductById(id) {
        this.isLoading = true;
        if (id !== null) {
            this.crud.getData('product/info?productId=' + id).subscribe((res: any) => {
                if (res.responseCode == 200) {
                    this.productDescription = res.products;
                    this.selectimage = this.productDescription[0].defaultImage;
                    this.productDescription[0].quantity = 1;
                    this.isLoading = false;
                }
            }, error => {
                this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
            })
        }
    }

    //smilar product
    public getSimilarProduct(catId) {
        // console.log(catId)
        this.crud.getData('product/category/list?categoryId=' + catId).subscribe((res: any) => {
            if (res.responseCode == 200) {
                if (res.products.length > 8) {
                    this.catDetails = res.products.splice(0, 6)
                    this.featuredProductList = res.products;
                } else {
                    this.catDetails = res.products
                }
            }
        })
    }

    public checkUser() {
        if (localStorage.getItem("type") === "Regular" || localStorage.getItem("type") == undefined) {
            return false;
        } else {
            return true;
        }
    }

    //select quantity
    public selectQuant(value) {
        this.productQuant = value;
    }

    public catDetails = [{ title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Printed Tees", image: "assets/img/pic4.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Striped", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Solid", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Printed Tees", image: "assets/img/pic4.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Striped", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: "Solid", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic3.png", subTitle: "Lorem Lorem Lorem", Price: 300 }]
    
    public addToCart(code) {
        // console.log(localStorage.getItem("customerid"))
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            fetch(this.crud.getEndPoint("cart/add"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    email: localStorage.getItem("email"),
                    customerId: localStorage.getItem("customerid"),
                    productId: code,
                    quantity: this.productQuant
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {
                    // console.log("added", response)
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                    this.router.navigate(['/cart-page'])
                }
            }).catch(error => console.error('Error:', error))
        }
    }

    public productDetails(catId) {
        // console.log(catId)
        this.router.navigate(['/catId/' + catId.categoryId + '/product-detalil/' + catId.prdcode]);        
        this.getProductById(catId.prdcode); 
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    public addToWishList(prodId) {
        if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
            this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
        } else {
            if (localStorage.getItem("customerid") !== null || localStorage.getItem("customerid") !== undefined) {
                fetch(this.crud.getEndPoint("wishlist/create"),
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                    },
                    body: qs.stringify({
                        customerId: localStorage.getItem("customerid"),
                        productId: prodId
                    })
                }).then(response => response.json())
                .then(response => {
                    if (response.responseCode == "200") {
                        this.helperService.wishListSubject.next(response.wishListItems)
                        this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                        this.router.navigate(['/wishlist']);
                        this.submitingData = false;
                    }
                }).catch(error => console.error('Error:', error));
                this.submitingData = false
            } else {
                this.toastr.warning("Please Login First", "Login In Required", { timeOut: 3000 })
                this.submitingData = false
            }
        }
    }   
}
