import { HomeComponent } from '../../pages/home/home.component';
import { CollectionComponent } from '../../pages/collection/collection.component';
import { ProductDetailComponent } from '../../pages/product-detail/product-detail.component';
import { CartPageComponent } from '../../pages/cart-page/cart-page.component';
import { Routes, RouterModule } from '@angular/router';
import { OrdersComponent } from '../../pages/orders/orders.component'
import { PaymentPageComponent } from '../../pages/payment-page/payment-page.component'
import { SavedAddressComponent } from '../../pages/account/saved-address/saved-address.component'
import { AccountComponent } from '../../pages/account/account.component';
import { AddressListComponent } from '../../pages/address-list/address-list.component';
import { WishlistComponent } from '../../pages/account/wishlist/wishlist.component';
import { ChangePasswordComponent } from '../../pages/account/change-password/change-password.component';
import {ShippingCompanyComponent} from '../../pages/shipping-company/shipping-company.component';
import {PickupLocationComponent} from '../../pages/pickup-location/pickup-location.component';

export const FULL_ROUTES: Routes = [

    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "collectionByCategory/:id", component: CollectionComponent },
    { path: "catId/:id/product-detalil/:id", component: ProductDetailComponent },
    { path: "cart-page", component: CartPageComponent },
    { path: "my-orders", component: OrdersComponent },
    { path: "account", component: AccountComponent },
    { path: "address-list", component: AddressListComponent },
    { path: "payment", component: PaymentPageComponent },
    { path: "address", component: SavedAddressComponent },
    { path: "wishlist", component: WishlistComponent },
    { path: "change-password", component: ChangePasswordComponent },
    { path: "shipping-list", component: ShippingCompanyComponent },
    { path: "pickup-location", component: PickupLocationComponent }
]