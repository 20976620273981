import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { OrderService } from '../../services/orderService.service';
import { Router } from '@angular/router'
declare const require: any;
declare var $: any;
var qs = require('qs');

@Component({
  selector: 'app-pickup-location',
  templateUrl: './pickup-location.component.html',
  styleUrls: ['./pickup-location.component.scss']
})
export class PickupLocationComponent implements OnInit {

	public isLoading: boolean = false;
	public productInfo: any = {};
	public cartListItem = [];
	public subTotal = 0;
	public pickupLocations = [];
	public tax = 0;
	public total = 0;
	public pickupLocation = 0;	
    public orderNote = "";

	constructor(private router: Router, 
		private order: OrderService, 
		private toastr: ToastrService, 
		private modalService: NgbModal, 
		private crud: CrudServiceService)
	{ 
		this.getCartData();
		this.getPickupLocation();
	}

	ngOnInit() {
		this.order.orderData.subscribe((res: any) => {           
            this.productInfo = res;
        });
        
        if( this.productInfo == null ){
            this.router.navigate(['/cart-page']);
        }
	}

	getPickupLocation()
	{
		this.crud.getData("shipping/pickup/location/list").subscribe((response: any) => {
            if (response.responseCode = "200") {      
            	console.log("TEst");          
            	console.log(response);
                this.pickupLocations = response.pickupLocations;
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
        });		
	}

	//get cart data
    getCartData() {
        console.log("method called");
        this.isLoading = true;
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            
            if (res.responseCode = "200") {
                this.cartListItem = res.cartItems;               
                this.subTotal = 0;
                this.cartListItem.forEach((element) => {
                    this.subTotal += (element.quantity * element.price); 
                     if (element.taxable == "Yes"){
               
                       this.tax += (((element.quantity * element.price ) * element.taxPercentage) / 100);  
                   }                  
                });

                this.calTax();
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
        })
    }

    calTax() {
        //this.tax = (+this.subTotal * +localStorage.getItem("taxValue")) / 100;
        this.total = this.subTotal + this.tax;       
        localStorage.setItem("cartTotal", String(this.total))
    }

    selectLocation($event,id){ 
        var elems = document.querySelectorAll(".btn.selected-location");
        [].forEach.call(elems, function(el) {
            el.classList.remove("selected-location");
        });
        $event.target.classList.add("selected-location");

		this.pickupLocation               = id;
		this.productInfo.pickupLocationId = id; 
		this.productInfo.addressId = 0;        

		var l:any = {};

		this.pickupLocations.map(function(location){
        	if(location.pickupLocationId == id){
        		l = location;
        	}
        	return false;
        });

		this.productInfo.pickupLocation = {
			address:l.address,
			city:l.city,
			state:l.cstate,    
			country:l.country,    
			zip:l.zip 
		}; 	
    }

    proceedToPay(){
        var elems = document.querySelectorAll(".btn.selected-location");
        if(elems.length <= 0){
            this.toastr.warning("warning", "Please select a location", { timeOut: 3000 });
            return false;
        }  
    	this.order.emitOrderInfo(this.productInfo);
        this.placeOrder();
    }

    placeOrder() {
        fetch(this.crud.getEndPoint("sales/order/initiate"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                customerId: localStorage.getItem("customerid"),
                addressId: 0,
                subTotal: this.productInfo.subTotal,
                totalPrice: this.productInfo.grandTotal,  
                shippingCharges: null,
                pickupLocationId:this.productInfo.pickupLocationId,
                notes:this.orderNote,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                this.productInfo.currency = this.cartListItem[0].currency;
                this.productInfo.orderId = response.orderId;
                this.productInfo.orderStatus = response.orderStatus;
                this.productInfo.notes = this.orderNote;
                this.order.emitOrderInfo(this.productInfo);
                
                this.router.navigate(['/payment']);               
                //this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });                
            }
        })
        .catch(error => console.error('Error:', error));
    }   
}
