import { Component, OnInit, NgZone } from '@angular/core';
import { OrderService } from '../../services/orderService.service';
import { Router } from '@angular/router'
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';

import { CrudServiceService } from '../../app-services/crud-service.service';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";
import { HelperService } from '../../app.service';
declare var Stripe: any;
declare const require: any;
var qs = require('qs');
declare var $: any;
var assert = require('assert');

@Component({
    selector: 'app-payment-page',
    templateUrl: './payment-page.component.html',
    styleUrls: ['./payment-page.component.scss']
})
export class PaymentPageComponent implements OnInit {
    // U7KKVZXQP1N
    public userEmail = "";
    PaystackPop: any;
    public inProcess: boolean = false;
    elements: Elements;
    card: StripeElement;
    public cartData: any;
    public selectedAddress: any = {};
    stripeTest: FormGroup;
    public token = null;
    public status = null;
    public orderData: any = {}
    public stripe = { valid: null, gateWayKey: null, accountId: null };
    elementsOptions: ElementsOptions = {
        locale: 'es'
    };
    public payStack = { valid: null, gateWayKey: null, accountId: null };
    public gatewayKeys = [];
    public isShippingChargesApplicatble: boolean = false;
    public shippingCharg = 0;   

    constructor(public order: OrderService, private fb: FormBuilder, private route: Router, private zone: NgZone,
        private stripeService: StripeService, private toastr: ToastrService, private crud: CrudServiceService, private service: HelperService) {
        // this.StripeScriptTag.setPublishableKey("pk_test_ENAdBjxDXayCcL2fc4TR8mVn00KdbWXEWd")
    }

    ngOnInit() {
        this.userEmail = localStorage.getItem("email")
        let paymentData = JSON.parse(localStorage.getItem("paymentGateWays"));
        paymentData.forEach(element => {
            if (element.gateWayName == "Paystack") {
                this.payStack.valid = true;
                this.payStack.gateWayKey = element.gateWayKey;
                this.payStack.accountId = element.accountId
            }
            if (element.gateWayName == "Stripe") {
                this.stripe.valid = true;
                this.stripe.gateWayKey = element.gateWayKey;
                this.stripe.accountId = element.accountId
            }
        });

        // console.log(localStorage.getItem("paymentGateWays"))
        this.order.orderData.subscribe((res) => {            
            this.cartData = res;           
            if(this.cartData.addressId  == 0){
                console.log(this.cartData.pickupLocation);
               this.selectedAddress = this.cartData.pickupLocation;
            }else{
                this.getAddress(this.cartData.addressId);   
                this.isShippingChargesApplicatble = true;
                this.getShippingCharge();
            }
            
            if (res.coupon == undefined || res.coupon == null) {
                this.cartData.coupon == null
            } else {
                this.cartData.coupon == res.coupon
            }
        })
    }

    //get Address
    getAddress(id) {
        this.crud.getData('customer/address/details?addressId=' + id).subscribe((res: any) => {
            if (res.responseCode == 200) {
                this.selectedAddress = res.addresses[0]
            }
        })
    }

    openCheckout() {
        var handler = (<any>window).StripeCheckout.configure({
            key: this.stripe.gateWayKey,
            locale: 'auto',
            token: (token: any) => {
                this.token = token.id;
                if (this.token) {
                    $("#spinner").show();
                    if (this.cartData.coupon) {
                        fetch(this.crud.getEndPoint("payment/stripe"),
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                            },
                            body: qs.stringify({
                                orderId: this.cartData.orderId,
                                cardToken: this.token,
                                couponNum: this.cartData.coupon
                            })
                        }).then(response => response.json())
                        .then(response => {
                            if (response.responseCode == "200") {
                                this.orderData.total = this.cartData.grandTotal;
                                this.orderData.currency = this.cartData.currency;
                                this.orderData.orderId = response.orderId;
                                this.orderData.paymentMode = response.paymentMode
                                this.getCart();
                                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                            } else if (response.responseCode == "100") {
                                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
                            }
                        })
                        .catch(error => console.error('Error:', error));
                    } else {
                        fetch(this.crud.getEndPoint("payment/stripe"),
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                            },
                            body: qs.stringify({
                                orderId: this.cartData.orderId,
                                cardToken: this.token
                            })
                        }).then(response => response.json())
                        .then(response => {
                            if (response.responseCode == "200") {
                                this.orderData.total = this.cartData.grandTotal;
                                this.orderData.currency = this.cartData.currency;
                                this.orderData.orderId = response.orderId;
                                this.orderData.paymentMode = response.paymentMode
                                this.getCart();
                                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                            } else if (response.responseCode == "100") {
                                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
                            }
                        })
                        .catch(error => console.error('Error:', error));
                    }
                }
            }
        });

        handler.open({
            name: 'Enter Card Details',
            description: 'Secure Payment',
            email:this.userEmail,
            amount: this.cartData.grandTotal * 100,
            currency: this.cartData.currency.trim().toLowerCase()
        });
    }

    getCart() {
        // console.log("cart data getting")
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            this.inProcess = false;
            this.order.emitOrderInfo(this.orderData);
            this.route.navigate(['thankyou'])
            if (res.responseCode = "200") {
                $("#spinner").hide();
                this.service.emitCartNumber(res.cartItems.length);
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
            }
        })
    }

    paymentDone(event) {
        this.status = event.status;
        if (this.status !== null && this.cartData.coupon == null) {
            $("#spinner").show();
            fetch(this.crud.getEndPoint("payment/paystack"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    orderId: this.cartData.orderId,
                    status: this.status
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {
                    this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
                        // this.route.navigate(['thankyou'])
                        if (res.responseCode = "200") {
                            this.orderData.total = this.cartData.grandTotal;
                            this.orderData.currency = this.cartData.currency;
                            this.orderData.orderId = response.orderId;
                            this.orderData.paymentMode = response.paymentMode;
                            this.getCart();
                            this.service.emitCartNumber(res.cartItems.length);
                        } else {
                            this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                        }
                    })
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                } else if (response.responseCode == "100") {
                    this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
                }
            })
            .catch(error => console.error('Error:', error));
        }
        if (this.status !== null && this.cartData.coupon !== null) {
            $("#spinner").show();
            fetch(this.crud.getEndPoint("payment/paystack"),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
                },
                body: qs.stringify({
                    orderId: this.cartData.orderId,
                    status: this.status,
                    couponNum: this.cartData.coupon
                })
            }).then(response => response.json())
            .then(response => {
                if (response.responseCode == "200") {
                    this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
                        this.zone.run(() => {
                            this.route.navigate(['thankyou']);
                        });

                        if (res.responseCode = "200") {
                            this.orderData.total = this.cartData.grandTotal;
                            this.orderData.currency = this.cartData.currency;
                            this.orderData.orderId = response.orderId;
                            this.orderData.paymentMode = response.paymentMode;
                            this.getCart();
                            this.service.emitCartNumber(res.cartItems.length);
                        } else {
                            this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                        }
                    })
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                } else if (response.responseCode == "100") {
                    this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
                }
            })
            .catch(error => console.error('Error:', error));
        }
    }

    serializeQuery(params, prefix) {
        const query = Object.keys(params).map((key) => {
            const value  = params[key];

            if (params.constructor === Array)
                key = `${prefix}[]`;
            else if (params.constructor === Object)
                key = (prefix ? `${prefix}[${key}]` : key);

            if (typeof value === 'object')
                return this.serializeQuery(value, key);
            else
                return `${key}=${encodeURIComponent(value)}`;
        });

        return [].concat.apply([], query).join('&');
    }

    getShippingCharge()
    {   
        var params = {totalPrice:this.cartData.total,
            subTotal:this.cartData.subTotal,
            shippingCompanyId:this.cartData.scompanyid,
            customerId:localStorage.getItem("customerid")  
        };

        var queryString = this.serializeQuery(params,null);

        this.crud.getData("shipping/charges?" + queryString ).subscribe((res: any) => {           
            if (res.responseCode = "200") {
                var shippingCharges = res.shippingCharges;
                this.shippingCharg = shippingCharges[0].charges;
                this.cartData.grandTotal = this.cartData.total + this.shippingCharg;
                this.cartData.paystackTotal = Math.round(this.cartData.total + this.shippingCharg);
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
            }
        })
    }   
}
