import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrderService {
    public orderData = new BehaviorSubject(null);
    public orderDataEmitter = this.orderData.asObservable();

    public emitOrderInfo(i): void {
        console.log("i", i)
        this.orderData.next(i);
    }
}