import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartModel } from './pages/models/cart.model';

@Injectable({
    providedIn: 'root'
})
export class HelperService implements OnInit {
    public cartListSubject = new BehaviorSubject<Array<CartModel>>([]);       // emits pages info list;
    public cartInfo = this.cartListSubject.asObservable();      // listens to pages subject
    public cartNumberSubject = new BehaviorSubject<number>(0);       // emits pages info list;
    public cartNumber = this.cartNumberSubject.asObservable();      // listens to pages subject
    public wishListSubject = new BehaviorSubject<Array<any>>([]);
    public wishList = this.wishListSubject.asObservable();
    public subCat = new BehaviorSubject(null);
    public subCatEmitter = this.subCat.asObservable();
    public emitCartListInfo(list: Array<CartModel>): void {
        this.cartListSubject.next(list);
       
    }
    public emitCartNumber(i): void {
        
        this.cartNumberSubject.next(i);
    }
    public emitWishList(wishlist: Array<any>): void {
        this.wishListSubject.next(wishlist)
      
    }
    public setSubCatId(id) {
        this.subCat.next(id);
    }
    ngOnInit() {
        this.cartNumber.subscribe((res) => {
           
        })
    }
}
