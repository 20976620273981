import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public info: any = {};
  public pages = [];
   public socialMedia = [];
  constructor(private crud: CrudServiceService) {
    this.getInfo()
  }

  ngOnInit() {
  }
  public getInfo() {
    this.crud.getData("merchant/organization/info").subscribe((res: any) => {
      if (res.responseCode == "200") {
       // console.log("footer", res)
        this.info = res.organization;
        this.pages =  res.pages;
      }
    })
  }
}
