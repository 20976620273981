import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CrudServiceService } from '../../../app-services/crud-service.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

declare const require: any;
declare var $: any;
var qs = require('qs');
var assert = require('assert');
@Component({
  selector: 'app-saved-address',
  templateUrl: './saved-address.component.html',
  styleUrls: ['./saved-address.component.scss']
})
export class SavedAddressComponent implements OnInit {

    public isLoading: boolean = false;
    public addressList = [];
    public latestAdress = {};
    public isloggedIn: boolean = false;
    public modalReference: NgbModalRef;
    public openAddAddressContent:any;
    public addAddressInfo = {
        customerId: localStorage.getItem("customerid"),
        address: null,
        city: null,
        state: null,
        zip: null,
        country: null,
    }

    constructor(private toastr: ToastrService, 
        private crud: CrudServiceService,
        private router: Router, 
        private modalService: NgbModal) {
        this.getAllAddress()

        if (localStorage.getItem("isloggedIn") == null) {        
            this.isloggedIn = false;
        } else {        
            this.isloggedIn = true;
        }
    }

    ngOnInit() {
    }
    
    public getAllAddress() {
        this.isLoading = true;
        this.crud.getData('customer/address/list?customerId=' + localStorage.getItem("customerid")).subscribe((res: any) => {
            // console.log("asedrftgyhujik")
            if (res.responseCode == 200) {
                this.addressList = res.addresses;
                this.latestAdress = this.addressList[this.addressList.length - 1]
                // console.log(this.addressList)
                this.isLoading = false;
            } else {
                this.toastr.error('Error', 'Something went wrong', { timeOut: 3000 })
            }
        })
    }

    public removeAddress(id) {        
        fetch(this.crud.getEndPoint("customer/address/remove"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                addressId: id,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("login", response)
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                this.getAllAddress()
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });
            }
        })
        .catch(error => this.toastr.error("Error", "Something went wrong", { timeOut: 3000 }));
    }

    public addAddress() {
        fetch(this.crud.getEndPoint("customer/address/create"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                customerId: this.addAddressInfo.customerId,
                address: this.addAddressInfo.address,
                city: this.addAddressInfo.city,
                state: this.addAddressInfo.state,
                zip: this.addAddressInfo.zip,
                country: this.addAddressInfo.country,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("login", response)
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                //this.modalReference.close();
                $("#addressForm").modal('hide');
                this.getAllAddress()
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
            }
        })
        .catch(error => this.toastr.error("Error", "Something went wrong", { timeOut: 3000 }));
    }

    public addnNewAddress(addAddress) {
        $("#addressForm").modal('show');
        //alert();
        //this.modalReference = this.modalService.open(addAddress, { backdrop: false, keyboard: false });
    }
}
