import { Component, OnInit,AfterViewInit } from '@angular/core';
import { HelperService } from '../../app.service';
import { Router } from '@angular/router';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;



@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
    public selectedItem = null;
    public active: boolean = false;
    public cartNumber = 0;
    public searchbox = false;
    public sidemenu = false;
    public cartItem = 0;
    public isloggedIn: boolean = false;
    public categoryList = [];
    public categoryListFirst  = [];
    public subCategories = [];
    public userName = "";
    public searchProduct = "";
    public currency = null;
    public itemId = null;
    public total = null;
    public cartTotal: any;
    public tax = null;
    public organiztionInfo = {
        orgname: null,
        logo: null,
        phone : null  
    }
    today: any;
    constructor(private helperService: HelperService, private router: Router, private crud: CrudServiceService, private toastr: ToastrService) {
        this.getCaterories();
        this.getInfo();
        this.getConfig();
        this.today = new Date();
        if (localStorage.getItem("isloggedIn") == null) {
            // this.cartTotal = localStorage.getItem("cartTotal")
            this.isloggedIn = false;
        } else {
            this.getCartList();
            this.isloggedIn = true;
        }
        this.userName = localStorage.getItem("firstname")
    }
    getConfig() {
            this.crud.getData('merchant/config').subscribe((res: any) => {
            //console.log("headerInfo", res)
            this.currency = res.currency;
            this.tax = res.taxValue
        })
    }
    //cartlist
    getCartList() {
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            //console.log("cartData", res)
            let totalTax = 0;
            res.cartItems.forEach((element) => {
                this.total += (element.quantity * element.price);
                 if (element.taxable == "Yes"){
                    totalTax += (((element.quantity * element.price ) * this.tax) / 100);                  
                 }
            })
            // let tax = +localStorage.getItem("taxValue");
            //console.log("total", this.total)
            let totalAmount = this.total + totalTax;
            
            this.crud.getData("shipping/charges?totalPrice=" + totalAmount +"&customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
                //console.log("shippingCharge", res)
                let shippingCharge = res.shippingCharges[0].charges;
                this.cartTotal = totalAmount + shippingCharge;
            })

        })
    }
    //organization info
    public getInfo() {
        this.crud.getData("merchant/organization/info").subscribe((res: any) => {
            if (res.responseCode == "200") {
                //console.log("responseCurrency", res)
                localStorage.setItem("logo", res.organization.logo),
                this.organiztionInfo.logo = res.organization.logo,
                this.organiztionInfo.orgname = res.organization.orgname
                this.organiztionInfo.phone = res.organization.phone;
            }
        })
    }
    //category List
    public getCaterories() {
        this.crud.getData("category/list").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.categoryList = res.categories;
                //console.log("catList", this.categoryList)
                this.categoryList.forEach((element, i) => {
                    this.crud.getData("category/subcategory/list?categoryId=" + element.catId).subscribe((res) => {
                        if (res.responseCode == 200) {
                            this.categoryList[i].subCategories = res.subcategories;
                        }
                    })

                })

            }
        }
        , error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        }
        )
    }
    signout() {
        localStorage.clear();
        this.router.navigate(['/login'])
    }
    //sub cat id
    over(item) {
        this.itemId = item
    }
    getCatId(id) {
        let body = {
            catId: id,
            subCatId: null
        }
        this.helperService.subCat.next(body);
        this.router.navigate(['/collectionByCategory/' + id])
    }
    catId(catId, subCat) {
        let body = {
            catId: catId,
            subCatId: subCat.subCategoryId
        }
        this.router.navigate(['/collectionByCategory/' + subCat.subCategoryId])
        this.helperService.subCat.next(body)
    }
    ngOnInit() {
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode = "200") {
                this.cartItem = res.cartItems.length;
            }
        })
        this.helperService.cartNumber.subscribe((res: any) => {
            //console.log("header", res)
            this.cartItem = res.cartLength;
            this.cartTotal = res.cartTotal;
            this.currency = res.currency;
        })
    }
    //get subCat
    getSubCategories(catId) {
        this.selectedItem = catId;
        //console.log("catId", catId)
        this.router.navigate(['/collectionByCategory/' + catId])
    } con
    openSearcBox() {
        this.searchbox = true;
    }
    closeSearcBox() {
        this.searchbox = false;
    }

    openSideMenu() {
        this.sidemenu = true;
        //console.log('side meun 8');
    }
    closeSideMenu() {
        this.sidemenu = false;
    }
    search(event) {
        if (event.keyCode == 13 && this.searchProduct != "") {
            // console.log(this.searchProduct)
            this.router.navigate(['/collectionByCategory/' + this.searchProduct])
        }
    }

    searchSubmit(){
        if (this.searchProduct != "") {
            // console.log(this.searchProduct)
            this.router.navigate(['/collectionByCategory/' + this.searchProduct])
        }
    }

    ngAfterViewInit() {
        setTimeout(function(){
            $(document).ready(function(){
                $(".subcategory_menu").on('click',function(){
                    $("#toggleMenu").trigger('click');
                });

                $(".main_cat").on('click',function(){
                    $("#toggleMenu").trigger('click');
                });

                if ($(window).width() > 767) {
                    $( "#menu .main-navigation li:first-child" ).addClass('active');
                    $( ".main-navigation > li" ).hover(
                        function() {
                            $( this ).parent('#menu ul').find('li.active').removeClass( "active" );
                            $( this ).addClass( "active" );
                        }
                        );
                }
                else {
                    $( "#menu .main-navigation li:first-child" ).removeClass('active');
                }
            });    
        },3000);            
    }
}
