import { Component, OnInit } from '@angular/core';
import { HelperService } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'
import { CrudServiceService } from '../../app-services/crud-service.service';
import { OrderService } from '../../services/orderService.service';
import * as CartActions from '../../services/cart-store/cart.action';
import * as fromCart from '../../services/cart-store/cart.reducer';
import { Store } from '@ngrx/store';
declare const require: any;
var qs = require('qs');
declare var $: any;

var assert = require('assert');

@Component({
    selector: 'app-cart-page',
    templateUrl: './cart-page.component.html',
    styleUrls: ['./cart-page.component.scss']
})
export class CartPageComponent implements OnInit {
    public grandTotal = null;
    public shippingCharges = '';
    public orderPlacing: boolean = false;
    public isLoading: boolean = false;
    public subTotal = 0;
    public tax = 0;
    public total = 0;
    public cartListItem = [];
    public cartListItemCopy = [];
    public orignalTotal = 0;
    public couponApplied = "";
    public couponInfo: any;
    public couponName = "";
    public currency = null;
    public applyActive = true;
    count = 0;
    public orderResponse = {
        currency: null,
        tax:null,
        coupon: null,
        total: null,
        orderId: null,
        orderStatus: null,
        cartItem: null,
        shippingCharges: null,
        grandTotal: null,
        subTotal:null
    };
    public quantity = [{ number: 1 }, { number: 2 }, { number: 3 }, { number: 4 }, { number: 5 }, { number: 6 }]
    public cartList = [{ title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }, { title: " Collor Tees", image: "assets/img/pic5.png", subTitle: "Lorem Lorem Lorem", Price: 300 }]

    constructor(
        private router: Router, 
        private service: HelperService, 
        private store: Store<fromCart.CartState>, 
        private toastr: ToastrService, 
        private crud: CrudServiceService, 
        private order: OrderService) 
    {
        console.log("called from constructor");
        this.getCartItem()
    }

    ngOnInit() {
        // console.log("from cart")
    }

    getCartItem() {
        console.log("method called");
        this.isLoading = true;
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            // console.log("cartData", res)
            if (res.responseCode = "200") {
                this.cartListItemCopy = res.cartItems;
                if (this.cartListItemCopy.length > 0) {
                    this.currency = this.cartListItemCopy[0].currency
                } else {
                    this.currency = JSON.parse(localStorage.getItem("currency"))
                }
                this.subTotal = 0;
                this.tax = 0;
                this.cartListItemCopy.forEach((element) => {
                    this.subTotal += (element.quantity * element.price);
                    this.orignalTotal = this.subTotal;
                     if (element.taxable == "Yes"){
               
                      this.tax += (((element.quantity * element.price ) * element.taxPercentage) / 100);  
                    }
                })
                this.calTax();
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
        })
    }
    
    calTax() {
        //this.tax = (+this.subTotal * +localStorage.getItem("taxValue")) / 100;
        this.total = this.subTotal + this.tax;
        this.calShippingCharge(this.total);
        localStorage.setItem("cartTotal", String(this.total))
    }

    calShippingCharge(total) {
        this.count = this.count + 1;
        console.log(this.count);
        this.crud.getData('shipping/charges?totalPrice=' + total).subscribe((res: any) => {
            console.log('sc', res)
            this.shippingCharges = res.shippingCharges[0].charges
            console.log('shippingCharges', this.shippingCharges)
            this.grandTotal = this.total + this.shippingCharges;
            let cartData = {
                cartLength: this.cartListItemCopy.length,
                cartTotal: this.grandTotal,
                currency: this.currency
            }
            this.service.emitCartNumber(cartData)
        })
    }

    updateCart(event, value) {
        // console.log('event', event)
        // console.log('val', value)
        fetch(this.crud.getEndPoint("cart/update"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                cartItemId: value.cartItemId,
                quantity: event
            })
        }).then(response => response.json())
        .then(response => {
            console.log("call from update");
            this.getCartItem();
            if (response.responseCode == "200") {
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
            }
        })
        .catch(error => console.error('Error:', error));
    }
    
    removeProduct(cartId) {
        fetch(this.crud.getEndPoint("cart/remove"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                cartItemId: cartId,
            })
        }).then(response => response.json())
        .then(response => {
            console.log("call from removed")
            this.getCartItem();
            if (response.responseCode == "200") {
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
            }
        })
        .catch(error => console.error('Error:', error));
    }

    applyCoupon(coupon) {
        if (coupon == "") {
            this.toastr.warning("Please enter a valid coupon number", "Coupon number is required", { timeOut: 3000 })
        } else {
            this.crud.getData("coupon/validate?couponNum=" + coupon).subscribe((res) => {
                console.log("coupon", res)
                if (res.responseCode == 200) {
                    console.log("coupon response", res)
                    this.couponInfo = res.coupon;
                    let d1 = new Date();
                    let d2 = new Date(this.couponInfo.batch.expiredate);
                    if (d2 > d1) {
                        this.couponName = coupon;
                        this.total = this.total - this.couponInfo.batch.couponval;
                        this.grandTotal = this.total + this.shippingCharges;
                        let cartData = {
                            cartLength: this.cartListItemCopy.length,
                            cartTotal: this.grandTotal,
                            currency: this.currency
                        }
                        this.service.emitCartNumber(cartData)
                        this.toastr.success("Coupon Applied successfully", "Sucess", { timeOut: 3000 })
                        this.applyActive = false;
                    } else {
                        this.toastr.warning("Sorry!!", "The coupon is expired", { timeOut: 3000 })
                    }
                } else {
                    this.toastr.error("Entered Coupon Number is Wrong", "Please check the coupon Number", { timeOut: 3000 })
                }
            })
        }
    }

    clearCoupon() {
        this.couponApplied = '';
        this.subTotal = this.orignalTotal;
        console.log("called from here 178");
        this.calTax();
        this.applyActive = true;
    }

    data() {
        this.orderResponse.coupon          = this.couponName;
        this.orderResponse.total           = this.total;        
        this.orderResponse.currency        = this.cartListItemCopy[0].currency;
        this.orderResponse.cartItem        = this.cartListItemCopy.length;
        this.orderResponse.shippingCharges = this.shippingCharges;
        this.orderResponse.subTotal        = this.subTotal;
        this.orderResponse.grandTotal      = this.grandTotal;
        this.order.emitOrderInfo(this.orderResponse);
    }

    placeOrder() {
        // console.log("coupon", this.orderResponse)
        this.orderPlacing = true;
        this.data()
        this.router.navigate(['/address-list']);
        /*this.orderResponse.cartItem = this.cartListItemCopy.length;
        fetch('https://merchant.scantranx.com/api/v1/sales/order/initiate',
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic ZGVtbzo0NzYxODY ='
            },
            body: qs.stringify({
                customerId: localStorage.getItem("customerid"),
                // addressId:
                totalPrice: this.subTotal,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                console.log("payment", response)
                this.orderResponse.currency = this.cartListItemCopy[0].currency;
                this.orderResponse.coupon = this.couponName;
                this.orderResponse.orderId = response.orderId;
                this.orderResponse.orderStatus = response.orderStatus;
                this.order.emitOrderInfo(this.orderResponse);
                this.router.navigate(['/address-list']);
                this.orderPlacing = false;
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });

            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });
                this.orderPlacing = false;
            }
        })
        .catch(error => console.error('Error:', error));*/
    }

    deliveryOption() {
        $("#deliveryOption").modal('show');
    }

    pickupByAddress(){
        $("#deliveryOption").modal('hide');
        this.orderPlacing = true;
        this.data()
        this.router.navigate(['/address-list']);
    }

    pickupByStoreLocation(){
        $("#deliveryOption").modal('hide');
        this.orderPlacing = true;
        this.data();
        this.router.navigate(['/pickup-location']);
    }
}
