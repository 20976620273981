import { Component, OnInit } from '@angular/core';
import { OrderService } from '../../services/orderService.service';
import { CrudServiceService } from '../../../app/app-services/crud-service.service'
@Component({
  selector: 'app-thankyou-page',
  templateUrl: './thankyou-page.component.html',
  styleUrls: ['./thankyou-page.component.scss']
})
export class ThankyouPageComponent implements OnInit {
  public payment: any;
  public info: any;
  constructor(private order: OrderService, private crud: CrudServiceService) {
    this.getInfo()
  }

  ngOnInit() {
    this.order.orderData.subscribe((res: any) => {
      this.payment = res
    })
  }
  getInfo() {
    this.crud.getData("merchant/organization/info").subscribe((res: any) => {
      if (res.responseCode == "200") {
        this.info = res.organization;
      }
    })
  }
}
