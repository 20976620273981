import { Component, OnInit,AfterViewInit } from '@angular/core';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { FeaturedProductsModel, CategoryList } from '../../shared/models/home.model';
import { HelperService } from '../../app.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
declare var $: any;
declare const require: any;
var qs = require('qs');

@Component({ selector: 'app-home', templateUrl: './home.component.html', styleUrls: ['./home.component.scss'] })
export class HomeComponent implements OnInit,AfterViewInit {
	public featuredProductList: Array<FeaturedProductsModel> = [];
	public snacksProductList: Array<FeaturedProductsModel> = [];
	public groceryProductList: Array<FeaturedProductsModel> = [];
  	public categoryList: Array<CategoryList> = [];
  	public brandList = [];
  	public bannerList = {};
  	public Image = "assets/img/deafultImg.png";
  	public featuredBrand = [];
  	public featuredBanner:any = {};
  	public productQuant: number = 1;
  	public categoryCollectionFirst:any = {};
  	public categoryCollectionSecond:any = {};

  	constructor(private router: Router, private crud: CrudServiceService, private toastr: ToastrService, private helpService: HelperService) {
    	this.getAllCategoryList();
    	this.getFeaturedProducts();
    	this.getBannerList();
    	this.getFeaturedBrand();
    	this.getBrandList();
    	this.getMerchantInfo();
		this.getCartList();		
  	}
	//cartList
	getCartList() {
		this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
			if (res.responseCode = "200") {

			}
		})
	}
	//marchantInfo
	getMerchantInfo() {
		this.crud.getData('merchant/config').subscribe((res) => {
			if (res.responseCode == 200) {
				localStorage.setItem("taxValue", res.taxValue);
				localStorage.setItem("paymentGateWays", JSON.stringify(res.paymentGateWays))
				localStorage.setItem("currency", JSON.stringify(res.currency))
			}
		})
	}
	//get brand
	getBrandList() {
		this.crud.getData('brand/list').subscribe((res: any) => {
			if (res.responseCode == 200) {
				this.brandList = res.brands
			}
		// console.log("brand", res)
		})
	}

  	//get featured brand 
  	getFeaturedBrand() {
    	this.crud.getData("banner/featured").subscribe((res: any) => {
            if (res.responseCode = 200) {
                this.featuredBanner = res.banners[0];                
            }
        },error => {
            this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
        });

        this.crud.getData('brand/featured').subscribe((res:any) => {
            if(res.responseCode = 200){
                this.featuredBrand = res.brands;
            }
        },error=>{
            this.toastr.error("Something went wrong",'Error',{timeOut:3000});
        });
	  }
	  
	//get banner List
	getBannerList() {
		this.crud.getData("banner/sliders").subscribe((res: any) => {
			if (res.responseCode = 200) {
				this.bannerList = res.sliders;
				// console.log("bannerList", this.bannerList[0])
			}
		}, error => {
			this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
		})
	}

	//get categoryList
	getAllCategoryList() {
		this.crud.getData("category/featured").subscribe((res: any) => {
			if (res.responseCode = 200) {
				this.categoryList = res.categories;
				// console.log(this.categoryList)
				this.getProductForSnacks();
				this.getProductForGrocery();
			}
		}, error => {
			this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
		})
	}

	//get fetured Products
	getFeaturedProducts() {
		this.crud.getData("product/featured").subscribe((res: any) => {
			if (res.responseCode == 200) {
				this.featuredProductList = res.products;	
				console.log("Featured PRoduct",this.featuredProductList);			
			}
		}, error => {
			this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
		})
	}

	showProducts(id) {
		let body = {
			catId: id,
			subCatId: null
		}
		this.helpService.subCat.next(body);
		this.router.navigate(['/collectionByCategory/' + id])
	}

	ngOnInit() {
		this.helpService.cartNumber.subscribe((numb: any) => {
			// console.log("from home", numb)
		})
	}

	collectionPage(data) {
		this.router.navigate(['/catId/' + data.categoryId + '/product-detalil/' + data.prdcode])
	}

	getProductForSnacks(){

		this.categoryCollectionFirst = this.categoryList[0];

		this.crud.getData("product/list?categoryId=" + this.categoryCollectionFirst.catId + "&channel=1").subscribe((res: any) => {
			if (res.responseCode == 200) {
				this.snacksProductList = res.products;				
			}
		}, error => {
			this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
		})
       
	}

	getProductForGrocery(){

		this.categoryCollectionSecond = this.categoryList[1];

		this.crud.getData("product/list?categoryId=" + this.categoryCollectionSecond.catId  + "&channel=1").subscribe((res: any) => {
			if (res.responseCode == 200) {
				this.groceryProductList = res.products;				
			}
		}, error => {
			this.toastr.error('Something went wrong', 'Error', { timeOut: 3000 });
		})
       
	}

	ngAfterViewInit() {

		$(function () {
			$('.common-home #subscribe-me').firstVisitPopup({
				cookieName : 'bigoc01',
				showAgainSelector: '#show-message'
			});
		});
		
		setTimeout(function(){ 
			$(".quickview").fancybox({
				type: 'iframe'
			});            
			$(".fancybox").fancybox();

			$('.product-carousel').owlCarousel({
				items: 5,
				autoPlay: false,
				singleItem: false,
				navigation: true,
				pagination: false,
				itemsDesktop : [1199,4],
				itemsDesktopSmall : [991,3],
				itemsTablet : [767,3],
				itemsTabletSmall : [650,2],
				itemsMobile : [479,1]
			});
		   
			$('.banner-carousel').owlCarousel({
				items: 2,
				autoPlay: false,
				singleItem: false,
				navigation: false,
				pagination: true,
				itemsDesktop : [1199,2],
				itemsDesktopSmall : [991,2],
				itemsTablet : [767,2],
				itemsTabletSmall : [479,1],
				itemsMobile : [319,1]
			});

			$('#slideshow0').swiper({
				mode: 'horizontal',
				slidesPerView: 1,
				pagination: '.slideshow0',
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				spaceBetween: 0,
				autoplay: 5000,
				autoplayDisableOnInteraction: true,
				loop: true
			});
			$('.productpage-carousel').owlCarousel({
				items: 5,
				autoPlay: false,
				singleItem: false,
				navigation: true,
				pagination: false,
				itemsDesktop : [1199,4],
				itemsDesktopSmall : [991,3],
				itemsTablet : [479,2],
				itemsMobile : [319,1]
			});
			$('.manufacture-slider').owlCarousel({
				items: 6,
				autoPlay: true,
				singleItem: false,
				navigation: false,
				pagination: false,
				itemsDesktop : [1199,5],
				itemsDesktopSmall : [991,4],
				itemsTablet : [767,3],
				itemsTabletSmall : [479,2],
				itemsMobile : [360,2]
			}); 

			this.refeaturedp();
			$(window).resize(function(){this.refeaturedp();});
			
			$(document).bind( "mouseup touchend", function(e){
				var container = $('.live-search');
				if (!container.is(e.target) && container.has(e.target).length === 0)
				{
					container.hide();
				}
			});

			$("#spinner").hide();

		},2000);
	}	


	refeaturedp(){
	    var size_item = $(".featured .section-product div.product-items").size();
	    if ($(window).width() > 650){
	      var x=5;
	    }
	    else{
	      var x=4;
	    }
	    $('.featured .nomore').hide();
	    $('.featured .section-product div.product-items:lt('+x+')').fadeIn('slow');
	    $('.loadmore').click(function () {
		    if(x==size_item){
		             $('.featured .nomore').show();
		              $('.featured .loadmore').hide();
		    }else{
		        if ($(window).width() > 650){
		         x= (x+5 <= size_item) ? x+5 : size_item;
		        }
		        else
		        {
		          x= (x+2 <= size_item) ? x+2 : size_item;
		        }
		        $('.featured .section-product div.product-items:lt('+x+')').fadeIn('slow');
		    }
	    });
	}


	addToCart(code) {
		// console.log(localStorage.getItem("customerid"))
		if (localStorage.getItem("customerid") == undefined || localStorage.getItem("customerid") == null) {
			this.toastr.warning("Please login first", "Login is Required", { timeOut: 3000 })
		} else {
			fetch(this.crud.getEndPoint("cart/add"),
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
				},
				body: qs.stringify({
					email: localStorage.getItem("email"),
					customerId: localStorage.getItem("customerid"),
					productId: code,
					quantity: this.productQuant
				})
			}).then(response => response.json())
			.then(response => {
				if (response.responseCode == "200") {
					// console.log("added", response)
					this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
					this.router.navigate(['/cart-page'])
				}
			}).catch(error => console.error('Error:', error))
		}
	}
}
