import { Component, OnInit } from '@angular/core';
import { CrudServiceService } from '../../../../app/app-services/crud-service.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router'
declare const require: any;
var qs = require('qs');
var assert = require('assert');
@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
    public isLoading: boolean      = false;
    public wishList                = [];
    public displayToaster: boolean = true;
    public isSaving: boolean       = false;
    public isloggedIn:boolean = false;

    constructor(public crud: CrudServiceService, public toastr: ToastrService, public router: Router) {
        this.getWishListData()
    }

    ngOnInit() {}

    public getWishListData() {
        this.isLoading = true;
        this.crud.getData("wishlist/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                // console.log(res)
                this.wishList = res.wishListItems;
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 });
                this.isLoading = false;
            }
        })
    }

    //remove from wishlist
    public removeProduct(id) {
        fetch(this.crud.getEndPoint("wishlist/remove"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                wishListId: id,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == 200) {
                this.getWishListData();
                if (this.displayToaster) {
                    this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 })
                }

            } else {
                if (this.displayToaster) {
                    this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                }
            }
        })
        .catch(error => console.error('Error:', error));
    }

    public addToCart(code, listId) {
        this.isSaving = true;
        // console.log(code)
        fetch(this.crud.getEndPoint("cart/add"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                email: localStorage.getItem("email"),
                customerId: localStorage.getItem("customerid"),
                productId: code,
                quantity: 1
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("added", response)
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                this.isSaving = false;
                this.displayToaster = false;
                this.removeProduct(listId)
                this.router.navigate(['/cart-page'])
            }
        }).catch(error => console.error('Error:', error));
        this.isSaving = false;
    }
}
