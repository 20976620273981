import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CrudServiceService } from '../../../app/app-services/crud-service.service';
import { OrderService } from '../../services/orderService.service';
import { Router } from '@angular/router'
declare const require: any;
declare var $: any;
var qs = require('qs');
var assert = require('assert')
@Component({
    selector: 'app-address-list',
    templateUrl: './address-list.component.html',
    styleUrls: ['./address-list.component.scss']
})
export class AddressListComponent implements OnInit {
    
    public addressList: Array<any> = [];
    public isLoading: boolean = false;
    public addressId = null;
    public addressAvailable: boolean = false;
    public couponApplied = "";
    public couponInfo: any;
    public couponName = "";
    public currency = null;
    public orderResponse = {
        currency: null,
        coupon: null,
        total: null,
        orderId: null,
        orderStatus: null,
        cartItem: null,
    };
    public addAddressInfo = {
        customerId: localStorage.getItem("customerid"),
        address: null,
        city: null,
        state: null,
        zip: null,
        country: null,
    }
    public orderPlacing: boolean = false;
    public productInfo: any = {};
    public subTotal = 0;
    public cartListItemCopy = [];
    public modalReference: NgbModalRef;
    public tax = 0;
    public total = 0;
    public orderNote = "";

    constructor(
        private router: Router, 
        private order: OrderService, 
        private toastr: ToastrService, 
        private modalService: NgbModal, 
        private crud: CrudServiceService) 
    {
        this.getAllAddress();
        this.getCartData()
    }

    ngOnInit() {
        this.order.orderData.subscribe((res: any) => {
            console.log("resService", res)
            this.productInfo = res;
        });
       
        if( this.productInfo == null ){
            this.router.navigate(['/cart-page']);
        }
    }

    public addnNewAddress() {
        $("#addressForm").modal('show');        
    }

    //get address list
    getAllAddress() {
        this.isLoading = true;
        this.crud.getData('customer/address/list?customerId=' + localStorage.getItem("customerid")).subscribe((res: any) => {
            if (res.responseCode == 200) {
                // console.log("address", res.addresses)
                this.addressList = res.addresses;
                if (this.addressList.length > 0) {
                    this.addressAvailable = true;
                }
                this.isLoading = false;
            } else {
                this.toastr.error('Error', 'Something went wrong', { timeOut: 3000 })
                this.isLoading = false;
            }
        })
    }

    //add address
    addAddress() {
        fetch(this.crud.getEndPoint("customer/address/create"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                customerId: this.addAddressInfo.customerId,
                address: this.addAddressInfo.address,
                city: this.addAddressInfo.city,
                state: this.addAddressInfo.state,
                zip: this.addAddressInfo.zip,
                country: this.addAddressInfo.country,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("login", response)
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                // this.closeModel();
                $("#addressForm").modal('hide');
                this.getAllAddress()
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 })
            }
        })
        .catch(error => this.toastr.error("Error", "Something went wrong", { timeOut: 3000 }));
    }  

    //remove saved dress
    removeAddress(id) {
        // console.log(id)
        fetch(this.crud.getEndPoint("customer/address/remove"),
        {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic c2hvcHB5OjExODk0MDY0'
            },
            body: qs.stringify({
                addressId: id,
            })
        }).then(response => response.json())
        .then(response => {
            if (response.responseCode == "200") {
                // console.log("login", response)
                this.toastr.success(response.responseMessage, response.description, { timeOut: 3000 });
                this.getAllAddress()
            } else if (response.responseCode == "100") {
                this.toastr.warning(response.responseMessage, response.description, { timeOut: 3000 });
            }
        })
        .catch(error => this.toastr.error("Error", "Something went wrong", { timeOut: 3000 }));
    }

    //select address
    selectAddress($event,id) {
        var elems = document.querySelectorAll(".btn.selected-address");
        [].forEach.call(elems, function(el) {
            el.classList.remove("selected-address");
        });

        $event.target.classList.add("selected-address");
        this.addressId = id;
        this.productInfo.addressId = this.addressId;       
    }

    //get cart data
    getCartData() {
        console.log("method called");
        this.isLoading = true;
        this.crud.getData("cart/list?customerId=" + localStorage.getItem("customerid")).subscribe((res: any) => {
            
            if (res.responseCode = "200") {
                this.cartListItemCopy = res.cartItems;
                if (this.cartListItemCopy.length > 0) {
                    this.currency = this.cartListItemCopy[0].currency
                } else {
                    this.currency = JSON.parse(localStorage.getItem("currency"))
                }
                this.subTotal = 0;
                 this.tax = 0;
                this.cartListItemCopy.forEach((element) => {
                 this.subTotal += (element.quantity * element.price);
                  if (element.taxable == "Yes"){
               
                       this.tax += (((element.quantity * element.price ) * element.taxPercentage) / 100); 
                   }                  
                })
                this.calTax();
                this.isLoading = false;
            } else {
                this.toastr.error("Error", "Something went wrong", { timeOut: 3000 })
                this.isLoading = false;
            }
        })
    }

    calTax() {
        //this.tax = (+this.subTotal * +localStorage.getItem("taxValue")) / 100;
        this.total = this.subTotal + this.tax;       
        localStorage.setItem("cartTotal", String(this.total))
    }

    deliveryOption(){
        var elems = document.querySelectorAll(".btn.selected-address");
        if(elems.length <= 0){
            this.toastr.warning("Address is required", "Please select an address", { timeOut: 3000 });
            return false;
        }  
        this.productInfo.notes = this.orderNote;
        
        this.order.emitOrderInfo(this.productInfo);
        this.router.navigate(['/shipping-list']);    
              
    }
}
